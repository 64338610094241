import clsx from 'clsx';
import React from 'react';
import './button.scss';

export interface ButtonProps {
	children: React.ReactNode;
	type?: 'regular' | 'diagonal';
	theme?: 'primary' | 'secondary';
	isSubmitButton?: boolean;
	onClick?: (ev?: any) => void;
}

const Button: React.FC<ButtonProps> = ({
	children,
	type = 'regular',
	theme = 'primary',
	isSubmitButton,
	onClick,
}: ButtonProps) => {
	return (
		<button
			className={clsx('btn', `btn--${type}`, `btn--${theme}`)}
			type={isSubmitButton ? 'submit' : 'button'}
      onClick={onClick}
		>
			{children}
		</button>
	);
};

export { Button };
