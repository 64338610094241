import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import React from 'react';

export interface CareerHeaderProps {
  pageName: string;
}

const CareerHeader: React.FC<CareerHeaderProps> = ({ pageName }: CareerHeaderProps) => {
  const { isSmallerScreen } = useGlobalSetting();
  return <div className="career-header">
    <h2 className="atmospheric-text">{pageName}</h2>
    <p className="corbel-text">
      If you are looking for a new PLAYGROUND to further your
      career, we might be your next challenge.
      {!isSmallerScreen && <br/>}We are dynamic and
      inclusive and we want to make a difference. 
      {!isSmallerScreen && <br/>}If you are game
      for the opportunity, send us your resume to
      <a href="mailto:info@playgroundonline.io">info@playgroundonline.io</a>
    </p>
  </div>;
};

export { CareerHeader };
