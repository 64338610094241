export const JOBLIST: JobItem[] = [
	{
		title: 'Smart Contract Developer',
		description:
      `
        <section>
          <h4>Responsibilities</h4>
          <ul>
            <li>Design/implement secure and reliable smart contracts in Solidity.</li>
            <li>Work with full stack developers to create full-fledged decentralized applications.</li>
            <li>Collaborate with other team members to plan projects.</li>
            <li>Perform design reviews and code reviews regularly.</li>
            <li>Create technical specifications and documentation.</li>
          </ul>
        </section>
        <section>
          <h4>Requirements</h4>
          <ul>
            <li>Over 3 years of experience in smart contract development.</li>
            <li>Familiar with the common smart contract vulnerabilities.</li>
            <li>Experience of smart contract development framework.</li>
            <li>Familiarity with open-source development and collaborations.</li>
            <li>Strong problem-solving and communication skills.</li>
          </ul>
        </section>
      `,
		url: '',
	},
	{
		title: 'Graphic Illustrator',
		description:
			`
        <section>
          <h4>Responsibilities</h4>
          <ul>
            <li>Designing and producing English and Chinese marketing materials in both print and web media</li>
            <li>Manage all steps of the illustration process starting with rough sketches to communicate your ideas and ending with delivering high-quality photorealistic or stylized key art</li>
            <li>High attention to detail and work within diverse and established brand guidelines</li>
            <li>Create and deliver character concept art to internal and external clients</li>
            <li>Collaborate with various departments including marketing, sales, and website production</li>
            <li>Ability to work on creative concepts on a team and individually</li>
          </ul>
        </section>
        <section>
          <h4>Requirements</h4>
          <ul>
            <li>A recognised Diploma/Degree in a relevant field</li>
            <li>At least three years of relevant experience</li>
            <li>Skilled with Adobe Creative Suite</li>
            <li>Good organisational and communication skills</li>
          </ul>
        </section>
      `,
		url: '',
	},
];
