import React from 'react';
import ShowMoreText from "react-show-more-text";
import { Button } from '../button';

import './job-list.scss';

export interface JobListProps {
	list: JobItem[];
}

const JobList: React.FC<JobListProps> = ({ list }: JobListProps) => {
	const getEncodedString = (roleName: string) => {
		return encodeURIComponent(`subject=Job application [${roleName}]&body=Hi, I wish to apply for the position of ${roleName} that is listed on your website.`);
	};

	return (
		<ul className="job-list">
			{list.map((item: JobItem, index: number) => (
				<li className="job-list__item" key={index}>
					<article>
						<h3 className="atmospheric-text">{item.title}</h3>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css corbel-text"
                anchorClass="anchor corbel-text"
                truncatedEndingComponent={"... "}
            >
              <div className="corbel-text" dangerouslySetInnerHTML={{ __html: item.description }} />
							<Button type="diagonal">
								<a href={`mailto:info@playgroundonline.io?${getEncodedString(item.title)}`}>Apply now</a>
							</Button>
            </ShowMoreText>
					</article>
				</li>
			))}
		</ul>
	);
};

export { JobList };
