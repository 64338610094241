import { JobList } from '@/components/job-list';
import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import '../styles/global.scss';
import '../styles/career-page.scss';
import { configs } from '@/constants/configs';
import { JOBLIST } from '@/constants/careers';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import { CareerHeader } from '@/components/career-header';

const pageName: string = 'Join Us!';

export default function Careers() {
	return (
		<DefaultLayout
			className="career-page"
			pageTitle={`${configs.defaultPageTitle} - ${pageName}`}
		>
			<CareerHeader pageName={pageName} />
			<JobList list={JOBLIST} />
		</DefaultLayout>
	);
}
